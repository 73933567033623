@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  width: 160px;
  height: 134px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  .blueTick {
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    width: 2rem;
    height: 2rem;
  }
  img {
    width: 4rem;
    height: 4rem;
  }
  .logoName {
    @extend .subtitle1;
    text-align: center;
  }
}
