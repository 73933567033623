@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
  padding: 8rem 0;
  .title {
    @extend .h2;
    text-align: center;
  }
  .iframeContainer {
    margin: 0 1rem;
  }
  .episodeContainer {
    display: flex;
    justify-content: center;
    margin: 0 0.5rem;
  }
}