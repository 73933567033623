@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  position: relative;
  align-items: center;
  gap: 2.5rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
  width: 80%;
  max-width: 1100px;
  border: 2px solid #ff9c41;
  border-radius: 16px;
  padding: 1rem;
  .containerTitle {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    color: #ff9c41;
    padding: 0 1rem;
    background-color: #171c2c;
    @extend .subtitle1;
  }
  legend {
    @extend .h3;
    top: -1.125rem;
    color: #ff9c41;
    background-color: #171c2c;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 5.5rem;
    .featureRow {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      flex: 1;
      .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        padding-left: 0;
        flex: 1;
        .title {
          @extend .h3;
          @include md {
            font-size: 30px;
          }
        }
        .description {
          @extend .p;
          text-align: center;
          color: $themeGrayBlue700;
        }
      }
      .imageWrapper {
        flex: 1;
        img {
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}

@include sm {
  .container {
    padding: 2rem;
  }
}

@include md {
  .container {
    padding: 3rem;
    .content {
      .featureRow {
        flex-direction: row;
        .imageWrapper {
          flex: 1;
        }
        .textContainer {
          flex: 1;
          align-items: flex-start;
          text-align: left;
          .description {
            text-align: left;
          }
        }
      }
    }
  }
}
