@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 80%;
  padding: 8rem 0;
  flex: 2;
  gap: 5rem;
  .iconsContainer {
    display: grid;
    grid-template-columns: repeat(2, 160px);
    grid-template-rows: repeat(6, 135px);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  .imageWrapper {
    display: none;
  }
  .partnerRow {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1.5rem;
    img {
      height: 48px;
    }
  }
}

@include sm {
  .container {
    .iconsContainer {
      grid-template-columns: repeat(3, 160px);
      grid-template-rows: repeat(4, 135px);
    }
  }
}

@include md {
  .container {
    flex-direction: row;
  }
}

@include lg {
  .container {
    .iconsContainer {
      grid-template-columns: repeat(4, 160px);
      grid-template-rows: repeat(3, 135px);
    }
  }
}
