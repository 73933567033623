@import '/src/styles/typography';

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  color: white;
  margin: auto;
  gap: 16px;
  padding: 180px 0px 128px;

  h4{
    @extend .subtitle1;
  }

  a {
    color: #00DDDD;
  }

  p {
    @extend .body;
    text-align: justify;
  }
}