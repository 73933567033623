@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  background: linear-gradient(180deg, #fad551 0%, #ff9c41 59.37%);
  border-radius: 0.5rem;
  width: 100%;
  max-width: 16rem;
  height: 3rem;
  @extend .h3;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
}

@include sm {
  .container {
    font-size: 18px !important;
  }
}
