@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 5.5rem;
  margin: 8rem 0;
  .title {
    @extend .h2;
    text-align: center;
  }
  .imagesContainer {
    width: 80%;
    max-width: 1100px;
  }
}
