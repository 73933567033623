@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  gap: 2rem;
  margin: 8rem 0;
  .title {
    @extend .h2;
    text-align: center;
  }
  .listContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 1;
  }
  .iframeContainer {
    margin: 0 1rem;
  }
}

@include sm {
  .contentContainer {
    .listContainer {
      max-width: 1100px;
    }
  }
}