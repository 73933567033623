@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  display: flex;
  width: 100%;
  height: 28rem;
  background-color: #292936;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: top center;
    -o-object-fit: cover;
    -o-object-position: top center;
  }
  .topGradient {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 204px;
    background: linear-gradient(rgba(#292936, 0.6), transparent);
  }
  .bottomGradient {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 30%;
    background: linear-gradient(transparent, #292936 70%);
  }
}

@include md {
  .container {
    .bottomGradient {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 20%;
      background: linear-gradient(transparent, #292936 70%);
    }
  }
}
