@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 80%;
  max-width: 1100px;
  .title {
    width: 100%;
    @extend .h3;
    @include md {
      font-size: 30px;
    }
  }
  .listContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .noOpeningText {
      @extend .subtitle1;
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .opening {
    height: 7.5rem;
    border-radius: 1.5rem;
    background: #343444;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem;
    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;
      .openingTitle {
        width: 100%;
        max-width: 400px;
        font-size: 1rem;
        @extend .subtitle1;
      }
      .typeLocationContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        .openingJobType {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          width: 100%;
          max-width: 200px;
          font-size: 1rem;
          @extend .subtitle1;
        }
        .openingLocation {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          width: 100%;
          max-width: 350px;
          font-size: 1rem;
          @extend .subtitle1;
        }
      }
    }
  }
  .applyButton {
    background-color: rgba(255, 255, 255, 0.05) !important;
    border-radius: 1.5rem;
    box-shadow: none !important;
    width: 5.7rem;
    height: 2rem;
    font-size: 14px;
    @extend .subtitle1;
  }
  .iconContainer {
    width: 15px;
    height: 15px;
    padding: 2.5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.divider {
  background-color: #646480;
  margin: 4rem 0 !important;
  width: 80%;
  max-width: 1100px;
}

@include md {
  .container {
    .opening {
      height: auto;
      padding: 0.875rem 1.125rem;
      .textContainer {
        flex-direction: row;
        align-items: center;
        gap: 0;
        .typeLocationContainer {
          flex-direction: row;
          align-items: center;
          gap: 0;
        }
      }
    }
  }
}
