@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.textOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .textBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 80vw;
    left: 24px;
    top: 50%;
    gap: 8px;
    text-shadow: 0px 3px 4px rgba($themeBG, 0.4);
    .title {
      @extend .hero;
      color: white;
    }
    .bubble {
      background-color: $themeBG;
      border-radius: 20px 20px 20px 0px;
      padding: 8px 16px;
      text-transform: uppercase;
      color: $themeCyan;
      h4 {
        @extend .subtitle1;
        @include md {
          font-size: 20px;
        }
      }
    }
    button {
      @extend .button-big;
      cursor: pointer;
      margin-top: 16px;
      border-radius: 8px;
      border: none;
    }
  }
}

@include sm {
  .textOverlay {
    .textBox {
      left: 10%;
      top: 338px;
      max-width: 550px;
      button {
        @extend .button-big;
        margin-top: 16px;
        border-radius: 8px;
        border: none;
      }
    }
  }
}