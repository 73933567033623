@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  padding: 240px 0;
  background-color: transparent;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #08142C;
  width: 100%;
  .teamSection {
    width: 50%;
  }
  .topGradient {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 5%;
    // background: linear-gradient(#08142C, transparent);
  }
  .bottomGradient {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 30%;
    background: linear-gradient(transparent, $themeBG 70%);
  }
}