@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 80%;
  padding: 4rem 0 10rem 0;
  flex: 2;
  gap: 5rem;
  .iconsContainer {
    display: grid;
    grid-template-columns: repeat(2, 160px);
    grid-template-rows: repeat(2, 135px);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  .imageWrapper {
    display: none;
  }
  .partnerRow {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1.5rem;
    img {
      height: 48px;
    }
  }
}

.bottomGradient {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 30%;
  background: linear-gradient(transparent, #08142c 70%);
}

@include sm {
  .container {
    .iconsContainer {
      grid-template-columns: repeat(3, 160px);
      grid-template-rows: repeat(2, 135px);
    }
  }
}

@include md {
  .container {
    flex-direction: row;
    .imageWrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      opacity: 1;
    }
  }
}

@include lg {
  .container {
    .iconsContainer {
      grid-template-columns: repeat(5, 160px);
      grid-template-rows: repeat(1, 135px);
    }
  }
}
