@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 80%;
  max-width: 1100px;
  .title {
    width: 100%;
    @extend .h3;
  }
  .applyButton {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 0.5rem;
    box-shadow: none;
    width: 100%;
    max-width: 14.8rem;
    height: 3rem;
    padding: 1rem;
    @extend .subtitle1;
  }
  .content {
    width: 100%;
    .iconContainer {
      width: 11px;
      height: 11px;
      padding: 2.5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .formContainer {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 2rem;
      .textFieldInputContainer {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: start;
        .textFieldInput {
          width: 100%;
          height: 3rem;
        }
      }
      .label {
        @extend .subtitle1;
      }
      .resumeButtonContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
        .resumeTitle {
          @extend .subtitle1;
        }
        .resumeButton {
          border-radius: 1.5rem;
          box-shadow: none;
          width: 135px;
          height: 2rem;
          background-color: white;
          color: black;
          font-size: 14px;
        }
        .uploadedResumeButton {
          @extend .resumeButton;
          background-color: $themeGrayBlue350;
          color: white;
        }
      }
      .sendButton {
        background-color: rgba(255, 255, 255, 0.4);
        border-radius: 0.5rem;
        box-shadow: none;
        width: 171px;
        height: 3rem;
        color: black;
        font-size: 1.125rem;
      }
    }
  }
}

.divider {
  background-color: #646480;
  margin: 64px 0 !important;
  width: 80%;
  max-width: 1100px;
}

@include md {
  .container {
    .content {
      .formContainer {
        .textFieldInputContainer {
          .textFieldInput {
            width: 336px;
          }
        }
      }
    }
  }
}
