$themeBG: #1A1A22;
$themeCyan: #00DDDD;
$themeOrange: #FF9C41;
$themeYellow: #FAD551;

$themeGrayBlue100: #1A1A22;
$themeGrayBlue350: #3E3E50;
$themeGrayBlue400: #4A4A5F;
$themeGrayBlue600: #8484A5;
$themeGrayBlue700: #9D9DBB;

