@import '/src/styles/breakpoints';
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


.hero {
  font-family: Ubuntu;
  font-size: 54px;
  font-weight: 700;
  line-height: 112%;
  letter-spacing: -3px;
  @include md {
    font-size: 72px;
  }
}

.h1 {
  font-family: Ubuntu;
  font-size: 42px;
  font-weight: 700;
  line-height: 112%;
  letter-spacing: -0.5px;
  text-align: left;
  @include md {
    font-size: 64px;
  }
}

.h2 {
  //styleName: H1;
  font-family: Ubuntu;
  font-size: 32px;
  font-weight: 700;
  line-height: 124%;
  letter-spacing: -0.5px;
  @include md {
    font-size: 45px;
  }
}

.h3 {
  //styleName: H3;
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 700;
  line-height: 128%;
  letter-spacing: -1px;
  @include md {
    font-size: 25px;
  }
}

.h4 {
  font-family: Ubuntu;
  font-size: 18px;
  font-weight: 500;
  line-height: 133%;
  letter-spacing: 0px;
  @include md {
    font-size: 24px;
  }
}

.subtitle1 {
  //styleName: Subtitle 1 bold;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  @include md {
    font-size: 18px;
  }
}

.subtitle2 {
  //styleName: Subtitle 2;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.3px;
  @include md {
    font-size: 16px;
  }
}

.subtitle2-bold {
  //styleName: Subtitle 2;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: 400;
  line-height: 125%;
  letter-spacing: -0.3px;
  font-weight: bold;
  @include md {
    font-size: 16px;
  }
}


.p {
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0px;
  // text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.4);
  @include md {
    font-size: 20px;
  }
}

.body {
  //styleName: Body;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: -0.1px;
  @include md {
    font-size: 14px;
  }
}

.button-big {
  //styleName: Button big;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.3px;
  @include md {
    font-size: 18px;
  }
}

.overline {
  //styleName: Overline;
  font-family: Ubuntu;
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @media (min-width: #{$screen-md-min}) {
    font-size: 10px;
  }
}