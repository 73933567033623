@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  margin: 12rem 1rem 10rem 1rem;
  max-width: 1100px;
  iframe {
    border-radius: 0.75rem;
    border: none;
    outline: none;
    width: 100%;
    height: 20rem;
    box-shadow: 0 2px $themeCyan;
  }
  .episodeInfoWrapper {
    margin-top: 2.5rem;
    .title {
      @extend .h3;
      @include md {
        font-size: 30px;
      }
      margin-bottom: 0.5rem;
    }
    .duration {
      @extend .subtitle2-bold;
    }
    .publishedDate {
      @extend .body;
      color: $themeCyan;
      margin-bottom: 0.5rem;
    }
    .description {
      @extend .body;
      p {
        @extend .body;
      }
      a {
        color: $themeCyan;
        font-weight: bold;
      }
    }
  }
}

@include sm {
  .container {
    iframe {
      height: 30rem;
    }
  }
}

@include md {
  .container {
    iframe {
      height: 39rem;
    }
  }
}