@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 3rem 1rem 6rem;
  h3{
    @include md {
      font-size: 30px;
    }
  }
  .toolBlockRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    .toolBlock {
      display: flex;
      flex: 1;
      max-width: 80%;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      box-shadow: 0px 24px 56px rgba(0, 0, 0, 0.5);
      gap: 1rem;
      padding: 2rem;
      border-radius: 1rem;
      color: rgba($color: #000000, $alpha: 1.0);
      .description {
        color: rgba($color: #000000, $alpha: 0.8);
      }
      &:first-child {
        background: linear-gradient(138.81deg, #7BFFC0 14.07%, #5494F5 87.73%);
      }
      &:last-child {
        background: linear-gradient(138.81deg, #FFCA7B 14.07%, #E25BF8 87.73%);
      }
    }
  }
}

@include md {
  .container {
    margin: 6rem 1rem;
    .toolBlockRow {
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      .toolBlock {
        width: 50%;
        max-width: 486px;
      }
    }
  }
}