@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 80%;
  margin: 148px 0 0px;
  gap: 48px;
  .textSection{
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 200%;
    &.notvisible {
      opacity: 0;
      transform: translateX(-200px);
    }
    &.visible {
      opacity: 1;
      transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-delay: 0.2s;
      transform: translateX(0px);
    }
    h2 {
      strong {
        color: $themeCyan;
      }
    }
  }
  .widgetSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    img {
      border-radius: 8px;
    }
  }
}

.imageSection {
  margin-bottom: -24px;
}

@include sm {
  .container {
    flex-direction: column;
    perspective: 300px;
    margin: 148px 0 -248px;
    width: 95%;
    .textSection {
      flex: 1;
      text-align: center;
    }
    .widgetSection {
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}

@include md {
  .container {
    flex-direction: row;
    padding: 64px;
    perspective: 800px;
    width: 85%;
    .textSection {
      text-align: left;
      margin-right: 120px;
    }
    .widgetSection {
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .imageSection {
    width: 100%;
    min-height: 600px;
    bottom: 0;
    margin-bottom: -8px;
    img {
      width: 100%;
    }
  }
}

@include lg {
  .container {
    .widgetSection {
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}