@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin: 8rem 1rem;
  padding: min(1rem, 5%);
  max-width: 1100px;
  .titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2.5rem;
    p {
      color: $themeGrayBlue700;
    }
  }
  .tableWrapper {
    width: 100%;
    max-width: 93vw;
    overflow-x: auto;
    table {
      border-collapse: collapse;
      width: 100%;
      col:nth-child(even) {
        background: rgba($color: #000000, $alpha: 0.1);
      }
      tbody {
        tr {
          &:hover {
            background-color: rgba($color: #fff, $alpha: 0.1);
          }
          &:last-child {
            td {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
            }
          }
        }
      }
      tr {
        height: 3rem;
        border: none;
        th {
          @extend .h3;
          color: $themeCyan;
          white-space: nowrap;
          padding: 0 min(0.5rem);
          &:last-child {
            color: $themeYellow;
          }
          .thContent {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 2rem 0 1rem;
          }
        }
        td {
          @extend .subtitle1;
          text-align: center;
          vertical-align: middle;
          padding: 0 min(0.5rem);
          border: none;
          white-space: nowrap;
          &:first-child {
            color: $themeGrayBlue700;
            text-align: left;
            max-width: 368px;
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
}
