@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  margin-bottom: 80px;
  width: 80%;
  max-width: 1100px;
  background-color: #2f2f3d;
  border-radius: 16px;
  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0 0 0;
    margin: auto;
  }
  .contentContainer {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .aboutContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .titleContainer {
        @extend .h3;
      }
      .descriptionContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        p {
          @extend .subtitle2;
          color: #9d9dbb;
        }
      }
    }
    .mediaContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .mediaTitle {
        font-size: 18px;
        @extend .h3;
      }
      .buttonContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
        .buttonWithIcon {
          @extend .subtitle2-bold;
        }
      }
    }
  }
}

@include md {
  .container {
    flex-direction: row;
    .imageContainer {
      padding: 2rem 0 0 2rem;
      margin: 0;
    }
    .contentContainer {
      .mediaContainer {
        .buttonContainer {
          flex-direction: row;
          justify-content: flex-start;
          .buttonWithIcon {
            width: auto;
          }
        }
      }
    }
  }
}
