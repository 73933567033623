@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 80%;
  padding: 0 0 4rem 0;
  .textWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 2;
    .comingSoonText {
      @extend .subtitle1;
      padding: 0.375rem 0.75rem;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 24px;
      width: fit-content;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .description {
      color: #9d9dbb;
      @extend .subtitle1;
      font-weight: 400;
    }
  }
  .imageWrapper {
    display: none;
  }
  .partnerRow {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1.5rem;
    img {
      height: 48px;
    }
  }
}

@include md {
  .container {
    margin: 148px 0 0px;
    .imageWrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      opacity: 1;
    }
  }
}
