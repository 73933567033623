@import "/src/styles/theme";
@import "/src/styles/typography";

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: $themeBG;
  color: white;
  overflow: hidden;
  h1 {
    @extend .h1;
  }
  h2 {
    @extend .h2;
  }
  h3 {
    @extend .h3;
  }
  h4 {
    @extend .h4;
  }
  p {
    @extend .p;
  }
}
