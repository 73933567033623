@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  column-count: 2;
  column-gap: 10px;
  figure {
    margin: 0;
    margin-bottom: 10px;
    overflow: hidden;
    &:nth-child(even) {
      margin-bottom: 0;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 335px;
      border-radius: 8px;
      object-fit: cover;
    }
  }
}

@include md {
  .container {
    column-count: 4;
  }
}
