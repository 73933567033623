@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 3rem 1rem 6rem;
  max-width: 1100px;
  padding: min(1rem, 5%);
  padding-bottom: 16rem;
  .titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    .imgContainer {
      display: flex;
      justify-content: center;
    }
    p {
      color: $themeGrayBlue700;
    }
  }
  .subtitleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2.5rem;
    p {
      color: $themeGrayBlue700;
    }
  }
  .featuresContainer {
    align-self: flex-start;
    margin-top: 2.5rem;
    .listWrapper {
      display: flex;
      flex-direction: column;
    }
  }
}

.itemWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 4rem;
  .iconContainer {
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    position: relative;
    .bigBullet {
      display: none;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    list-style-position: outside;
    li {
      @extend .body;
      position: relative;
      display: flex;
      flex-direction: row;
      margin: 0.5rem 0;
      &::before {
        content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $themeCyan;
        display: inline-block;
        font-weight: bold;
        width: 1em;
        margin-left: 0rem;
      }
      .listContent {
        @extend .subtitle2;
        color: $themeGrayBlue700;
      }
    }
  }
}

.bottomGradient {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 16rem;
  background: linear-gradient(transparent, #08142c 70%);
}

@include sm {
  .container {
    .featuresContainer {
      border-left: 2px solid $themeGrayBlue400;
      padding: 0 4rem;
    }
  }

  .itemWrapper {
    .title {
      h3{
        @include md {
          font-size: 30px;
        }
      }
      .bigBullet {
        display: block;
        position: absolute;
        left: -4.5rem;
        top: 1em;
        width: 1rem;
        height: 1rem;
        background-color: white;
        border-radius: 100%;
        box-shadow: 0 0 0 1em rgba($color: #fff, $alpha: 0.05);
      }
    }
    ul {
      li {
        &::before {
          width: 1em;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
