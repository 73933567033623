@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 1rem;
  overflow-x: auto;
  table {
    text-align: left;
    width: 100%;
    min-width: 600px;
    border-radius: 0.5rem;
    overflow: hidden;
    thead {
      tr {
        background-color: rgba($color: #000000, $alpha: 0.6);
        th {
          @extend .overline;
          padding: 1rem;
        }
      }
    }
    tbody {
      tr {
        @extend .subtitle2;
        font-weight: bold;
        height: 104px;
        cursor: pointer;
        &:nth-child(odd) {
          background-color: rgba($color: black, $alpha: 0.2);
        }
        &:nth-child(even) {
          background-color: rgba($color: black, $alpha: 0.1);
        }
        &:hover {
          background-color: rgba($color: white, $alpha: 0.05);
        }
        td {
          padding: 0.5rem;
          &.thumbnail {
            position: relative;
            width: 104px;
            .thumbnailImgWrapper {
              display: flex;
              position: relative;
              img {
                object-fit: contain;
                width: 100%;
                border-radius: 0.25rem;
              }
            }
          }
          &.episodeTd {
            position: relative;
            max-height: 100%;
            max-width: 300px;
            .episodeInfoWrapper {
              height: 104px;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: 0.5rem;
              .name {
                @extend .subtitle2-bold;
              }
              .description {
                @extend .body;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                        line-clamp: 2; 
                -webkit-box-orient: vertical;
                color: $themeGrayBlue700;
              }
            }
          }
          &.publishedTd {
            min-width: 6rem;
          }
          &.lengthTd {
            min-width: 6rem;
          }
          &.playButtonTd {
            width: 64px;
            .playButtonWrapper {

            }
          }
        }
      }
    }
  }
}
