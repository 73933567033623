@import url("https://fonts.googleapis.com/css?family=Ubuntu:400'");
$font: "Ubuntu", sans-serif;
$white: #FFF;
$dark: #1A1A22;
$darkTranslucent: rgba(52,52,68,0.6);
$cyan: #00DDDD;
$orange: #FF9C41;

@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.middle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  h1 {
    font-family: $font;
    color: $white;
  }
  input[type="radio"] {
    display: none;
    &:checked {
      + .box {
        background-position: 0 -100%;
        span {
          color: $dark;
          transform: translateY(25%);
          &:before {
            transform: translateY(0%);
            opacity: 1;
          }
        }
      }
    }
    &:not(:checked) + .box:hover{
      box-shadow: inset 0 0 6px rgba(132,132,165,1);
    }
  }
}
label:first-child > input[type="radio"]:checked+.box{
  background-position: 100% 0;
}
label:last-child > input[type="radio"]:checked+.box{
  background-position: -100% 0;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100px;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, $darkTranslucent 50%, $orange 50%);
  transition: all 250ms cubic-bezier(.2,0,.2,1);
  will-change: transition;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-family: $font;
  font-weight: 900;
  span {
    @extend .subtitle1;
    text-shadow: none;
    font-weight: bold;
    position: absolute;
    transform: translate(0, 5px);
    left: 0;
    right: 0;
    transition: all 250ms cubic-bezier(.2,0,.2,1);
    user-select: none;
    color: $white;
    &:before {
      font-family: FontAwesome;
      display: block;
      transform: translateY(-50px);
      opacity: 0;
      transition: all 250ms cubic-bezier(.2,0,.2,1);
      font-weight: normal;
      color: $dark;
    }
  } 
}
label:first-child > .box{
  -webkit-border-top-left-radius: 25px;
  -webkit-border-bottom-left-radius: 25px;
  -moz-border-radius-topleft: 25px;
  -moz-border-radius-bottomleft: 25px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
label:last-child > .box{
  -webkit-border-top-right-radius: 25px;
  -webkit-border-bottom-right-radius: 25px;
  -moz-border-radius-topright: 25px;
  -moz-border-radius-bottomright: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-image: linear-gradient(to left, $cyan 50%, $darkTranslucent 50%);
}

@include sm {
  .box {
    display: inline-block;
    width: 300px;
    height: 200px;
  }
  .developer {
    span {
      &:before {
        content: url("https://caliberdesign.se/stuff/pogr/developer.svg");
      }
    }
  }
  .gamer {
    span {
      &:before {
        content: url("https://caliberdesign.se/stuff/pogr/gamer.svg");
      }
    }
  }
}