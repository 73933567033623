
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view) small desktop
$screen-md-min: 996px;

// Desktops
$screen-lg-min: 1600px;

// Large Screens
$screen-xl-min: 2600px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}