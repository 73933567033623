@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0px 1.5rem;

  .menuOpenContainer {
    display: flex;
    position: absolute;
    right: 0;
    margin-left: auto;
  }
}

.container {
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  height: 88px;
  margin: 0px 0px;
  transition: background-color 0.3s ease-in-out;
  &.darkmode {
    background-color: $themeBG;
    color: white;
  }
  &.lightmode {
    background-color: transparent;
    color: black;
  }
  .brandContainer {
    max-width: 188px;
    cursor: pointer;
    img {
      object-fit: cover;
    }
  }
  .navlinksContainer {
    display: flex;
    margin: auto;
    flex-direction: row;
    gap: 32px;
    .itemContainer {
      display: flex;
      cursor: pointer;
      padding: 4px 8px;
      border: 3px transparent solid;
      filter: drop-shadow(0px 0px 24px rgba(0, 221, 221, 0.5));
      transition: all 0.2s;
      text-decoration: solid;
      &.active {
        color: $themeCyan;
        border-bottom: 3px $themeCyan solid;
        filter: drop-shadow(0px 0px 24px rgba($themeBG, 0.5));
      }
      &:hover {
        color: $themeCyan;
      }
      h4 {
        @extend .subtitle1;
        filter: drop-shadow(0px 0px 24px rgba(0, 221, 221, 0.5));
      }
      &:first-child {
        &.active {
          color: $themeOrange;
          border-bottom: 3px $themeOrange solid;
          filter: drop-shadow(0px 0px 24px rgba($themeOrange, 0.5));
        }
        &:hover {
          color: $themeOrange;
        }
        h4 {
          filter: drop-shadow(0px 0px 24px rgba($themeOrange, 0.5));
        }
      }
    }
  }
}

.navlinksContainerMobile {
  display: flex;
  flex-direction: column;
  flex: 1;
  .navBrandingMobile {
    display: flex;
    height: 88px;
    align-items: center;
    padding-left: 1.5rem;
  }
  .navItemMobile {
    display: flex;
    flex: 1;
    align-items: flex-start;
    padding: 0.5rem 1.5rem;
    color: white;
    &.active {
      color: $themeCyan;
      border-right: 3px $themeCyan solid;
      filter: drop-shadow(0px 0px 24px rgba($themeBG, 0.5));
    }
  }
}

@include sm {
  .content {
    margin: 0px 48px;
  }
  .container {
    .brandContainer {
      display: flex;
    }
    .navlinksContainer {
      display: flex;
      margin-left: auto;
      margin-right: 0;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}

@include md {
  .content {
    margin: 0px 50px;
  }
} 

@include lg {
  .content {
    margin: 0px 200px;
  }
} 