@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  img {
    max-width: 100vw;
    height: 75vh;
    object-fit: cover;
    object-position: 50% top;
    -o-object-fit: cover;
    -o-object-position: 50% top;
  }
  video {
    max-width: 100vw;
    height: 75vh;
    object-fit: cover;
    object-position: 40% top;
    -o-object-fit: cover;
    -o-object-position: 40% top;
  }
  .topGradient {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 204px;
    background: linear-gradient(rgba($themeBG, 0.6), transparent);
  }
  .bottomGradient {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 30%;
    background: linear-gradient(transparent, $themeBG 70%);
  }
}

@include sm {
  .container { 
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top center;
      -o-object-fit: cover;
      -o-object-position: top center;
    }
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top center;
      -o-object-fit: cover;
      -o-object-position: top center;
    }
  }
}

@include md {
  .container { 
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top center;
      -o-object-fit: cover;
      -o-object-position: top center;
    }
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top center;
      -o-object-fit: cover;
      -o-object-position: top center;
    }
  }
}