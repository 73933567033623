@import '/src/styles/breakpoints';
@import '/src/styles/typography';
@import '/src/styles/theme';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #132439;
  video {
    max-width: 100vw;
    height: 85vh;
    object-fit: cover;
    object-position: 57% top;
    -o-object-fit: cover;
    -o-object-position: 57% top;
  }
  .topGradient {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 204px;
    background: linear-gradient(rgba(#132439, 0.6), transparent);
  }
  .bottomGradient {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 30%;
    background: linear-gradient(transparent, #132439 70%);
  }
}

@include sm {
  .container { 
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top center;
      -o-object-fit: cover;
      -o-object-position: top center;
    }
    video {
      width: 100%;
      min-height: 700px;
      height: auto;
      object-fit: cover;
      object-position: top center;
      -o-object-fit: cover;
      -o-object-position: top center;
    }
  }
}

@include md {
  .container { 
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top center;
      -o-object-fit: cover;
      -o-object-position: top center;
    }
    video {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: top center;
      -o-object-fit: cover;
      -o-object-position: top center;
    }
    .bottomGradient {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 20%;
      background: linear-gradient(transparent, #132439 70%);
    }
  }
}