@import "/src/styles/breakpoints";
@import "/src/styles/typography";
@import "/src/styles/theme";

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 48px;
  margin: 80px 0;
  background-color: transparent;
  opacity: 0;
  transform: translateY(60px);
  width: 100vw;
  &.visible {
    transform: translateY(0px);
    transition: all 0.4s;
    transition-delay: 0.4s;
    opacity: 1;
  }
  img {
    display: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 48px 16px;
    width: 80%;
    max-width: 1100px;
    .description {
      //styleName: Subtitle 1;
      font-family: Ubuntu;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.3px;
      text-align: center;
      color: $themeGrayBlue700;
      margin-top: 1rem;
    }
    h1 {
      color: $themeCyan;
      text-align: center;
    }
    h2{
      color: $themeCyan;
      text-align: center;
    }
    h3 {
      text-align: center;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  width: 80%;
  max-width: 1100px;
  .titleWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    .title {
      text-align: left;
      @extend .h3;
    }
    .description {
      color: #9D9DBB;
      text-align: left;
      @extend .body;
    }
  }
}

.divider {
  background-color: #646480;
  margin: 64px 0 !important;
  width: 100%;
}

@include md {
  .row {
    img {
      display: block;
      width: 90px;
      &.leftQ {
        margin-top: -80px;
      }
      &.rightQ {
        margin-top: 80px;
      }
    }
  }
}
